<template>
  <article class="Article">
    <div class="Article-Wrapper">
      <h1 v-html="title"></h1>
      <p
          v-for="(p, i) in disclaimer"
          :key="i"
          class="Text Article-Disclaimer"
          v-html="p"
      ></p>
    </div>
    <img v-if="image" :src="require(`@/assets/images/articles-img/${image}`)" alt="">
    <div class="Article-Wrapper">
      <template v-for="(name, key) in articles">
        <template v-if="key.indexOf('title') !== -1">
          <component
              :is="'h4'"
              :key="key"
              class="Title"
          >
            {{ articles[key] }}
          </component>
        </template>
        <template v-else>
          <p
              v-for="(text, index) in articles[key]"
              :key="`${key}_${index}`"
              class="Text"
              v-html="text"
          >
          </p>
        </template>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  name: "Article",
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    disclaimer: {
      type: [Array, Object],
      default: () => {
      },
    },
    articles: {
      type: [Array, Object],
      default: () => {
      },
    },
  },
}
</script>

<style lang="scss">
.Article {
  padding: 100px 0;

  @media (min-width: $screen-l) {
    padding: 180px 0;
  }

  &-Wrapper {
    @media (min-width: $screen-l) {
      width: 815px;
      margin: 0 auto;
    }
  }

  h1 {
    margin-bottom: 12px;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.18;
    text-transform: uppercase;
    color: var(--color-text-main2);

    @media (min-width: $screen-l) {
      margin-bottom: 34px;
      font-size: 52px;
    }
  }

  &-Disclaimer {
    margin-bottom: 34px;
    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }

  > img {
    margin-bottom: 40px;
    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }

  h4 {
    margin: 40px 0 14px;
    font-size: 28px;
    line-height: 1.12;

    &:first-child {
      margin: 0 0 14px;
    }

    @media (min-width: $screen-l) {
      margin: 43px 0 14px;
      font-size: 40px;
    }
  }

  span,
  a {
    font-weight: 700;
    color: var(--color-text-main2);
  }

  span {
    text-transform: uppercase;
  }

  a {
    position: relative;

    &:hover {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-flex;
        width: 100%;
        height: 2px;
        background-color: var(--color-text-main2);
        text-transform: none;
      }
    }
  }

  p {
    > img {
      display: block;
      width: 200px;
      height: 200px;
    }
  }
}
</style>